html,
body,
main {
  margin: 0;
  font-family: Calibre, 'San Francisco', sans-serif;
  scroll-behavior: smooth;
}

.flex {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

header {
  background: linear-gradient(rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65)),
    url('../../assets/color_bg.jpg') no-repeat center center / cover;
  height: 100vh;
}

.text {
  font-size: 2em;
  color: white;
  font-variant: small-caps;
  font-weight: normal;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  margin: 0 0 0 20px;
  -webkit-font-smoothing: antialiased;
  width: 75%;
}

.name {
  color: orange;
}

/* main section */
#about,
#projects,
footer {
  position: relative;
  text-align: center;
  overflow: hidden;
}

#about {
  background-color: #23221f;
  color: #fff;
}

#contact {
  margin: 0 0 20px 0;
}

.footer {
  background-color: #22779b;
  height: 100%;
  justify-content: center;
}

.about-title,
.projects-title,
.contact-title {
  font-size: 2em;
  color: #22779b;
  font-variant: small-caps;
  font-weight: normal;
  margin: 0 0 20px;
  -webkit-font-smoothing: antialiased;
}

.about-text,
.about-list {
  text-align: left;
}

.about-text {
  margin: 0 20px;
  font-size: 1.2em;
  font-variant: small-caps;
  line-height: 1.5em;
}

.hit-me-up {
  text-decoration: none;
  color: orange;
  font-weight: bolder;
  font-size: 1.1em;
}

.me-image {
  position: absolute;
  bottom: -12%;
  left: -10%;
  width: 100%;
  mix-blend-mode: lighten;
}

/* Project section */
.projects-single {
  flex: 1;
  position: relative;
  background-color: #22779b;
  max-height: 213px;
}

.projects-single img {
  width: 100%;
  transition: all 0.5s;
}
.projects-single:hover img {
  opacity: 0.1;
}

.single-title {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  opacity: 0;
  transition: all 0.5s;
  color: #fff;
  z-index: 2;
  font-variant: small-caps;
  font-weight: bold;
  font-size: 1.1em;
}

.single-links {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  position: absolute;
  bottom: 0;
  opacity: 0;
  transition: all 0.5s;
  color: #fff;
  z-index: 2;
  width: 100%;
  background-color: #444649;
}

.single-links p {
  margin: 0;
  bottom: 0;
  padding: 10px 0;
}

.single-links a {
  text-decoration: none;
  font-variant: small-caps;
  color: #fff;
  padding: 10px 0;
}

.projects-single:hover .single-title {
  opacity: 1;
  top: 10%;
}

.projects-single:hover .single-links {
  opacity: 1;
  bottom: 10%;
}

.project-single .header-bar {
  background: #444649;
  height: 4px;
  margin: 25px 0 25px 0;
  width: 70px;
}

/* Form styles */
.contact-form {
  margin: 20px auto 0 auto;
}

.contact-intro {
  font-variant: small-caps;
  color: #22779b;
}

.form-email,
.form-name,
.form-message {
  border: 0;
  box-sizing: border-box;
  color: #000;
  font-size: 12pt;
  margin-bottom: 3px;
  outline: none;
  padding: 10px 15px;
  width: 100%;
}

input,
textarea {
  padding: 10px;
  font-size: 1.2em;
}

textarea {
  min-height: 150px;
}

label {
  text-align: left;
  padding: 0 0 0 10px;
  background-color: #fff;
  font-variant: small-caps;
}

.contact-button {
  border: 2px solid #22779b;
  background-color: #fff;
  color: #22779b;
  padding: 10px 40px;
  margin: 10px 0 0 0;
  font-size: 1.2em;
  font-variant: small-caps;
  font-weight: bold;
  transition: all 0.5s;
}

.contact-button:hover {
  background-color: #22779b;
  color: #fff;
}

.icon-group {
  margin: 0 auto;
}

.icons {
  height: 40px;
  margin: 20px;
}

.copy {
  color: #fff;
  font-weight: bold;
  font-variant: small-caps;
}

/* Next Section Buttons */
.chevron-btn {
  background: 0;
  border: 0;
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
  /* opacity: 0.5; */
  margin: auto;
  text-align: center;
  outline: 0;
  overflow: visible;
  -moz-appearance: none;
  appearance: none;
  -webkit-appearance: none;
  text-transform: none;
}

.chevron-btn:hover {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

.chevron-btn-wrapper {
  position: relative;
  overflow: hidden;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  -webkit-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
}

.chevron-btn-circle,
.chevron-btn-circleShadow {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
}

.chevron-btn-circleShadow {
  -webkit-transition: -webkit-transform 0.5s ease-in-out;
  transition: -webkit-transform 0.5s ease-in-out;
  -o-transition: transform 0.5s ease-in-out;
  transition: transform 0.5s ease-in-out;
  transition: transform 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
  -webkit-transform-origin: left center;
  -ms-transform-origin: left center;
  transform-origin: left center;
  -webkit-transform: translateX(-10%) rotate(-180deg);
  -ms-transform: translateX(-10%) rotate(-180deg);
  transform: translateX(-10%) rotate(-180deg);
}

.chevron-btn-circle {
  background-color: #fff;
}

.chevron-btn:hover {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

.chevron-btn-wrapper:hover {
  box-shadow: 5px 5px 5px;
}

.chevron-btn:hover .chevron-btn-circleShadow {
  -webkit-transform: rotate(0);
  -ms-transform: rotate(0);
  transform: rotate(0);
}

.chevron-btn-circleShadow-c1 {
  background-color: orange;
  -webkit-transition-delay: 70ms;
  -o-transition-delay: 70ms;
  transition-delay: 70ms;
}

.chevron-btn:hover .chevron_btn-circleShadow-c1 {
  background-color: orange;
  -webkit-transition-delay: 0s;
  -o-transition-delay: 0s;
  transition-delay: 0s;
}

.chevron-btn-circleShadow-c2 {
  background-color: pink;
  -webkit-transition-delay: 0.12s;
  -o-transition-delay: 0.12s;
  transition-delay: 0.12s;
}

.chevron-btn:hover .chevron_btn-circleShadow-c2 {
  background-color: pink;
  -webkit-transition-delay: 0.1s;
  -o-transition-delay: 0.1s;
  transition-delay: 0.1s;
}

.chevron-btn-circleShadow-c3 {
  background-color: #22779b;
}

.chevron-btn:hover .chevron-btn-circleShadow-c3 {
  background-color: #22779b;
  -webkit-transition-delay: 0.2s;
  -o-transition-delay: 0.2s;
  transition-delay: 0.2s;
}

.chevron-btn-label {
  position: relative;
  font-size: 1.5em;
  margin: 8px 0 0 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 0.1em;
  color: #000;
  -webkit-transition: 0.1s;
  -o-transition: 0.1s;
  transition: 0.1s;
  -webkit-transition-delay: 0.3s;
  -o-transition-delay: 0.3s;
  transition-delay: 0.3s;
}

.chevron-btn:hover .chevron-btn-label {
  color: #fff;
  -webkit-transition-delay: 0.4s;
  -o-transition-delay: 0.4s;
  transition-delay: 0.4s;
}

@media all and (min-width: 325px) {
  #about {
    height: 100vh;
  }

  .projects-single {
    max-height: 266px;
  }
}

@media all and (min-width: 600px) {
  #projects {
    height: 100vh;
  }

  .projects-row.flex {
    flex-direction: row;
    width: 600px;
    height: 200px;
    margin: 0 auto;
  }

  .projects-all {
    margin: 10%;
  }

  footer {
    height: 100vh;
  }

  .text,
  .about-title,
  .projects-title,
  .contact-title {
    font-size: 3em;
  }

  #about {
    margin: 0 auto;
  }

  .about-text {
    width: 400px;
    margin: 0 auto 40px;
  }

  .me-image {
    width: 75%;
  }

  #contact {
    margin: 0 0 125px 0;
  }

  .contact-form {
    width: 400px;
  }
}

@media all and (min-width: 700px) {
  .text,
  .about-title,
  .projects-title,
  .contact-title {
    font-size: 4em;
  }

  .me-image {
    width: 70%;
  }

  .about-text {
    width: 600px;
  }
}

@media all and (min-width: 900px) {
  .text,
  .about-title,
  .projects-title,
  .contact-title {
    font-size: 5em;
  }

  .projects-row.flex {
    width: 800px;
    height: 266px;
  }

  .me-image {
    width: 50%;
    bottom: -15%;
  }
}

@media all and (min-width: 1000px) {
  .me-image {
    width: 47%;
    bottom: -10%;
  }
}

@media all and (min-width: 1200px) {
  .me-image {
    width: 35%;
    left: -5%;
  }
}
